import Avatar from '@mui/material/Avatar'
import AccountCircle from '@mui/icons-material/AccountCircle'
import useAuth from '../../hooks/useAuth'

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

// TODO: dynamically change text color to best contrast background color
function stringAvatar(name, { size = 32, fontSize = 14 } = {}) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      color: 'white',
      width: size,
      height: size,
      fontSize: fontSize,
      border: '1px solid white',
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  }
}

const ProfileAvatar = ({ size, fontSize }) => {
  const { session } = useAuth()
  const name =
    session?.userAttributes?.given_name &&
    session?.userAttributes?.family_name &&
    `${session?.userAttributes?.given_name} ${session?.userAttributes?.family_name}`
  return (
    <>
      {name ? (
        <Avatar
          {...stringAvatar(name, {
            size: size,
            fontSize: fontSize,
          })}
        />
      ) : (
        <AccountCircle />
      )}
    </>
  )
}

export default ProfileAvatar
