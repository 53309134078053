import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import MenuItem from '@mui/material/MenuItem'
import useBaseName from '../../hooks/useBaseName'
import ProfileAvatar from '../Avatar/ProfileAvatar'
import ProfileMenu from '../Menu/ProfileMenu'
import logo from '../../assets/images/tethys-ai1_1__1_-removebg-preview.png'
import { popoverClasses } from '@mui/material/Popover'
import { useTheme } from '@emotion/react'
import { useNavigate } from 'react-router-dom'

const NavigationMenu = ({
  anchorElNav,
  handleCloseNavMenu,
  pages,
  pagesOnClick,
  currentPageIndex,
}) => {
  const theme = useTheme()
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{
        display: { xs: 'block', md: 'none' },
        [`& .${popoverClasses.paper}`]: {
          borderRadius: 0,
          maxWidth: '100%',
        },
      }}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 70.34, left: 0 }}
      MenuListProps={{
        style: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
      PaperProps={{
        elevation: 0,
        style: {
          width: '100%',
          backgroundColor: theme.palette.secondary.main,
        },
      }}
    >
      {pages.map((page, index) => (
        <MenuItem
          key={page}
          onClick={pagesOnClick[index]}
          color="inherit"
          selected={index === currentPageIndex}
          sx={{ height: '44px', backgroundColor: theme.palette.primary.main }}
        >
          <Typography color="white" textAlign="center">
            {page}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  )
}

const ResponsiveAppBar = ({
  pages,
  settings,
  pagesOnClick,
  settingsOnClick,
  pagesIcon,
  currentPageIndex,
  loggedIn,
}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const [anchorElUser, setAnchorElUser] = React.useState(null)
  const basename = useBaseName()
  const navigate = useNavigate()
  const theme = useTheme()

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar position="sticky" color="primary" elevation={0}>
      <Toolbar
        disableGutters
        sx={{
          height: 70.34,
          maxWidth: '1200px',
          display: 'flex',
          width: '100%',
          margin: 'auto',
          px: 2,
        }}
      >
        <Typography
          variant="h6"
          noWrap
          component="a"
          href={'https://tethys.ai/'}
          sx={{
            mr: 4,
            display: 'flex',
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
            flexGrow: 1,
          }}
        >
          <img src={logo} alt="TETHYS AI logo" style={{ height: 50 }} />
        </Typography>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            sx={{ color: 'white' }}
          >
            <MenuIcon />
          </IconButton>
          <NavigationMenu
            anchorElNav={anchorElNav}
            handleCloseNavMenu={handleCloseNavMenu}
            pages={pages}
            pagesOnClick={pagesOnClick}
            currentPageIndex={currentPageIndex}
          />
        </Box>
        <Stack
          direction="row"
          sx={{
            display: { xs: 'none', md: 'flex' },
            height: '100%',
          }}
        >
          {pages.map((page, index) => (
            <Button
              key={page}
              onClick={pagesOnClick[index]}
              sx={{
                color:
                  currentPageIndex === index
                    ? theme.palette.secondary.main
                    : 'inherit',
                px: 2.5,
                fontSize: '15px',
                fontWeight: 600,
                textTransform: 'unset !important',
              }}
              disableRipple
              disableFocusRipple
              disableTouchRipple
              startIcon={pagesIcon?.[index]}
            >
              {page}
            </Button>
          ))}
        </Stack>
        {loggedIn ? (
          <Box sx={{ flexGrow: 0, paddingLeft: '10px' }}>
            <Tooltip title="Your profile and settings">
              <IconButton onClick={handleOpenUserMenu} color="inherit">
                <ProfileAvatar />
              </IconButton>
            </Tooltip>
            <ProfileMenu
              anchorElUser={anchorElUser}
              handleCloseUserMenu={handleCloseUserMenu}
              settings={settings}
              settingsOnClick={settingsOnClick}
            />
          </Box>
        ) : (
          <Box sx={{ flexGrow: 0 }}>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              onClick={() => {
                navigate(`${basename}`)
              }}
              sx={{ textTransform: 'none', marginLeft: 2 }}
            >
              Login
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default ResponsiveAppBar
