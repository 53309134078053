import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import logo from '../assets/images/tethys_logo.png'

// TODO: Make this component match that of google's 404 error page
export default function Unautorized() {
  const location = useLocation()

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Box>
        <img
          src={logo}
          alt="TETHYS AI logo"
          style={{ height: 50, marginBottom: 10 }}
        />
        <Typography component="div" mb={2}>
          <Box fontWeight="fontWeightMedium" display="inline">
            403.{' '}
          </Box>
          Forbidden.
        </Typography>
        <Typography>
          You don't have permission to access {location.pathname} on this
          server.
        </Typography>
        <Typography>That's all we know.</Typography>
      </Box>
    </Grid>
  )
}
