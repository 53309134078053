/**
 * Retrive the basename path for application frontend
 * @param {string} groups - application backend relative route
 * @returns {string}
 */
export const getBaseName = (groups) => {
  if (groups?.find((group) => group === 'admin')) {
    return '/admin/'
  } else {
    return '/'
  }
}
