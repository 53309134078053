import { Divider, Typography, MenuItem, Menu, Grid } from '@mui/material'
import ProfileAvatar from '../Avatar/ProfileAvatar'
import useAuth from '../../hooks/useAuth'
import { styled, alpha } from '@mui/material/styles'

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    border: '1px solid #e2e2e2ff',
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}))

const ProfileMenu = ({
  anchorElUser,
  handleCloseUserMenu,
  settings,
  settingsOnClick,
}) => {
  const { session, logout } = useAuth()
  const name =
    session?.userAttributes?.given_name &&
    session?.userAttributes?.family_name &&
    `${session?.userAttributes?.given_name} ${session?.userAttributes?.family_name}`
  const email = session?.userAttributes?.email
  return (
    <StyledMenu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
      elevation={0}
    >
      <Grid
        container
        spacing={2}
        marginX={0}
        marginTop={0}
        marginBottom={2}
        width={350}
      >
        <Grid item xs={12}>
          <Typography variant="body1">Account</Typography>
        </Grid>
        <Grid item xs={2}>
          <ProfileAvatar size={36} fontSize={16} />
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            {name && (
              <Grid item xs={12}>
                <Typography variant="body1">{name}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body1">{email}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {settings.map((setting, index) => (
        <MenuItem key={setting} onClick={settingsOnClick[index]}>
          <Typography textAlign="center">{setting}</Typography>
        </MenuItem>
      ))}
      <Divider />
      <MenuItem onClick={() => logout()}>
        <Typography textAlign="center">Log out</Typography>
      </MenuItem>
    </StyledMenu>
  )
}

export default ProfileMenu
