import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar/Navbar'
import useBaseName from '../hooks/useBaseName'
import { Stack, Box } from '@mui/material'
import Footer from '../components/Footer/Footer'

const getCurrentPageIndex = (location, pages) => {
  const path = location.pathname.split('/').slice(-1)[0]
  const index = pages.findIndex((page) => page.href === path)
  return index
}

const BasicLayout = ({ loggedIn }) => {
  const navigate = useNavigate()
  const basename = useBaseName()
  const location = useLocation()

  const pages = [
    { label: 'Home', href: '' },
    { label: 'About', href: 'about' },
    { label: 'Pricing', href: 'pricing' },
    { label: 'Blog', href: 'blog' },
    { label: 'Contact', href: 'contact' },
  ]
  const currentPageIndex = getCurrentPageIndex(location, pages)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Navbar
        pages={pages.map((page) => page.label)}
        settings={['Manage account']}
        pagesOnClick={[
          () => {
            window.location.href = 'https://tethys.ai/'
          },
          () => {
            window.location.href = 'https://tethys.ai/about/'
          },
          () => {
            window.location.href = 'https://tethys.ai/pricing/'
          },
          () => {
            window.location.href = 'https://tethys.ai/blog/'
          },
          () => {
            window.location.href = 'https://tethys.ai/contact/'
          },
        ]}
        settingsOnClick={[() => navigate(`${basename}account`)]}
        currentPageIndex={currentPageIndex}
        loggedIn={false}
      />
      <Stack
        mt={5}
        mb={10}
        spacing={4}
        px={2}
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '1200px',
          width: '100%',
        }}
      >
        <Outlet />
      </Stack>
      <Footer />
    </Box>
  )
}

export default BasicLayout
