import { useState } from 'react'
import { Paper, Box, Tab } from '@mui/material'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import Login from './Login'
import SignUp from './SignUp'
import VerifyEmail from './VerifyEmail'

const paperStyle = {
  width: 340,
  margin: '20px auto',
}

const SignInUpPage = ({ defaultTab }) => {
  const [value, setValue] = useState(defaultTab)
  const [verify, setVerify] = useState(false)
  const [user, setUser] = useState(null)
  const handleTab = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Paper elevation={0} variant="outlined" style={paperStyle}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleTab}
            aria-label="Sign-in / Sign-up tab"
            centered
          >
            <Tab label="Sign In" value="1" />
            <Tab label="Sign Up" value="2" disabled />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Login handleTab={handleTab} />
        </TabPanel>
        <TabPanel value="2">
          {verify ? (
            <VerifyEmail user={user} />
          ) : (
            <SignUp setVerify={setVerify} setUser={setUser} />
          )}
        </TabPanel>
      </TabContext>
    </Paper>
  )
}

SignInUpPage.defaultProps = {
  defaultTab: '1',
}

export default SignInUpPage
