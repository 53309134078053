import { React } from 'react'
import { Routes, Route } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@material-ui/core'
import SignInUpPage from './pages/SignInUpPage'
import NoMatchPage from './pages/NoMatchPage'
import Unautorized from './pages/UnauthorizedPage'
import LoginLayout from './layouts/LoginLayout'
import { AuthProvider } from './context/AuthContext'
import theme from './theme'

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Routes>
            {/* public routes */}
            <Route element={<LoginLayout />}>
              <Route path="login" exact element={<SignInUpPage />} />
            </Route>

            <Route path="unauthorized" element={<Unautorized />} />

            {/* catch all  */}
            <Route path="*" element={<NoMatchPage />} />
          </Routes>
        </ThemeProvider>
        <ReactQueryDevtools initalIsOpem={false} position="bottom-right" />
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
