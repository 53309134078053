import { createTheme } from '@mui/material/styles'

const palette = {
  primary: {
    main: '#293A68',
    light: '#718cd1',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#60BB98',
    light: '#8BD8BD',
    dark: '#2F825E',
    contrastText: '#ffffff',
  },
  tertiary: {
    main: '#718cd1',
    light: '#ffffff77',
    dark: '#243665',
  },
  black: {
    main: '#231f20',
    contrastText: '#ffffff',
  },
  neutral: {
    main: '#d1d2d4',
    contrastText: '#231f20',
  },
  standard: {
    main: '#ffffff',
    contrastText: '#000000',
  },
  progress: {
    disabled: '#ffffff77',
    active: '#ffffff',
  },
}

const theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Montserrat',
    body1: {
      fontWeight: 400,
      fontSize: '15px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '12px',
    },
    body3: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '15px',
    },
    body4: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '14px',
    },
    body5: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '16px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '30px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '25px',
    },
    h4: {
      fontWeight: 600,
      fontSize: '20px',
    },
    h7: {
      fontFamily: 'Montserrat',
      fontWeight: 800,
      fontSize: '18px',
    },
  },
  components: {
    // Name of the component
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // currently, you need to provide all of the default mapping because it will replace, not merge.
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          h7: 'h6',
          subtitle1: 'h6',
          subtitle2: 'h6',
          body1: 'p',
          body2: 'p',
          body3: 'p',
          body4: 'p',
          body5: 'p',
          inherit: 'p',
          // You have to add this line
          // otherwise the `span` (display `inline` by default)
          // cannot have margin.
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          fontSize: '15px',
          fontWeight: 600,
          paddingTop: 8,
          paddingBottom: 8,
          paddingLeft: 24,
          paddingRight: 24,
          disableElevation: true,
        },
        contained: {
          '&:hover': {
            backgroundColor: palette.secondary.main,
          },
        },
        // root: ({ ownerState }) => ({
        //   ...(ownerState.variant === 'contained' &&
        //     ownerState.color === 'primary' && {
        //       backgroundColor: '#293A68',
        //       color: '#ffffff',
        //     }),
        // }),
      },
    },
  },
})

export default theme
