import { createContext, useEffect, useState } from 'react'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import { UserPool as Pool } from '../utils/cognito'
import { useNavigate } from 'react-router-dom'
import jwt from 'jwt-decode'

export const AuthContext = createContext()

// TODO: add basePath to contextData
export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    getSession().catch((err) => console.log(err))
  }, [])

  const getSession = () => {
    const promise = new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            // TODO: handle loading state
            // Scenario A: An error has occured while attempting to update the session (i.e. invalid refresh token)
            logout() // NOTE: user must be logged out for reauthentication
            reject(err)
          } else {
            // Scenario B: Session has been successfully retrieve and updated
            user.getUserAttributes((err, attributes) => {
              if (err) {
                // TODO: handle loading state
                logout() // NOTE: user must be logged out for reauthentication
                reject(err)
              } else {
                const userAttributes = {}
                for (let attribute of attributes) {
                  const { Name, Value } = attribute
                  userAttributes[Name] = Value
                }
                setSession({
                  ...session,
                  userAttributes,
                })
                if (loading) setLoading(false)
                resolve({
                  ...session,
                  userAttributes,
                })
              }
            })
          }
        })
      } else {
        // Scenario C: Session information not in local storage
        setSession(null)
        if (loading) setLoading(false)
        reject('Current user does not exist.')
      }
    })
    return promise
  }

  const getSessionFromLocalStorage = () => {
    const user = Pool.getCurrentUser()
    if (user) {
      const accessToken =
        user.storage?.[`${user.keyPrefix}.${user.username}.accessToken`]
      const clockDrift =
        user.storage?.[`${user.keyPrefix}.${user.username}.clockDrift`]
      const idToken =
        user.storage?.[`${user.keyPrefix}.${user.username}.idToken`]
      const refreshToken =
        user.storage?.[`${user.keyPrefix}.${user.username}.refreshToken`]
      return {
        accessToken: { jwtToken: accessToken, payload: jwt(accessToken) },
        clockDrift,
        idToken: { jwtToken: idToken, payload: jwt(idToken) },
        refreshToken: { token: refreshToken },
      }
    }
  }

  const login = (Username, Password) => {
    const promise = new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool })
      const authDetails = new AuthenticationDetails({ Username, Password })
      user.authenticateUser(authDetails, {
        onSuccess: (session) => {
          user.getUserAttributes((err, attributes) => {
            if (err) {
              logout()
              reject(err)
            } else {
              const userAttributes = {}
              for (let attribute of attributes) {
                const { Name, Value } = attribute
                userAttributes[Name] = Value
              }
              setSession({
                ...session,
                userAttributes,
              })
              resolve({
                ...session,
                userAttributes,
              })
            }
          })
        },

        onFailure: (err) => {
          setSession(null)
          reject(err)
        },

        newPasswordRequired: (session) => {
          setSession({ ...session, newPasswordRequired: true })
          resolve({ ...session, newPasswordRequired: true })
        },
      })
    })
    return promise
  }

  const logout = () => {
    const user = Pool.getCurrentUser()
    setSession(null)
    user?.signOut()
    navigate('/login')
  }

  const contextData = {
    login,
    getSession,
    session,
    getSessionFromLocalStorage,
    logout,
    setSession,
  }

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  )
}
