import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { CognitoUser } from 'amazon-cognito-identity-js'
import {
  Grid,
  Paper,
  Typography,
  Link,
  Stack,
  FormHelperText,
  Snackbar,
  Alert,
} from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextField from '../../components/Form/TextField'
import Button from '../../components/Form/Button'
import useAuth from '../../hooks/useAuth'
import SnackbarAlert from '../../components/Alert/SnackbarAlert'
import { UserPool as Pool } from '../../utils/cognito'
import { getBaseName } from '../../utils/frontend'
import logo from '../../assets/images/tethys_icon192.png'

const ERROR = {
  NOT_AUTHORIZED: 'NotAuthorizedException',
  RESOURCE_NOT_FOUND: 'ResourceNotFoundException',
  USER_NOT_CONFIRMED: 'UserNotConfirmedException',
  INVALID_PARAMETER: 'InvalidParameterException',
}

const INITIAL_FORM_STATE = {
  email: '',
  password: '',
}

const FORM_VALIDATION = Yup.object().shape({
  email: Yup.string().required('Enter email'),
  password: Yup.string().required('Enter password'),
})

const loginErrorMessage = (error, Username, setOpenVerify, onClick) => {
  if (error) {
    const linkStyle = { color: '#d32f2f' }
    switch (error.name) {
      case ERROR.INVALID_PARAMETER:
      case ERROR.NOT_AUTHORIZED:
        // TODO: develop reset password workflow
        return (
          <Typography variant="body1">
            {'Incorrect email or password. Please try again or '}
            <Link
              style={linkStyle}
              component="button"
              type="button"
              variant="body1"
              onClick={onClick}
            >
              reset your password
            </Link>
            .
          </Typography>
        )
      case ERROR.USER_NOT_CONFIRMED:
        // TODO: verify email reverification workflow
        const user = new CognitoUser({ Pool, Username })
        return (
          <Typography variant="body1">
            {
              'Your email has not been verified. Please check your email for a verification link. If you did not receive the email, '
            }
            <Link
              style={linkStyle}
              component="button"
              type="button"
              variant="body1"
              onClick={() => {
                if (user) {
                  user.resendConfirmationCode((err, data) => {
                    if (err) {
                      console.error(err)
                    } else {
                      setOpenVerify(true)
                      console.log(data)
                    }
                  })
                } else {
                  console.error('No user information available')
                }
              }}
            >
              click here to request another
            </Link>
            .
          </Typography>
        )
      default:
        // TODO: send error to error logging service
        // return error.message
        return (
          <Typography variant="body1">
            An unexpected error has occured. Contact us at info@tethys.ai if you
            are unable to sign in.
          </Typography>
        )
    }
  } else {
    return null
  }
}

const Login = ({ handleTab }) => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(null)
  const [error, setError] = useState(null)
  const [open, setOpen] = useState(true)
  const [openVerify, setOpenVerify] = useState(false)
  const { login } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const errorMessage = loginErrorMessage(error, email, setOpenVerify, () =>
    navigate('/forgetpassword')
  )

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const handCloseVerify = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenVerify(false)
  }

  useEffect(() => {
    window.history.replaceState({}, document.title)
  }, [])

  const paperStyle = {
    padding: 20,
    width: 300,
    margin: '20px auto',
  }
  const btnStyle = {
    marginTop: '16px',
    marginBottom: '8px',
  }

  return (
    <Grid>
      {location.state?.error && (
        <Snackbar
          open={open}
          autoHideDuration={null}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <SnackbarAlert
            onClose={handleClose}
            severity={location.state.error.severity}
          >
            {location.state.error.message}
          </SnackbarAlert>
        </Snackbar>
      )}
      <Paper elevation={0} style={paperStyle}>
        <Snackbar
          open={openVerify}
          autoHideDuration={6000}
          onClose={handCloseVerify}
        >
          <Alert
            onClose={handCloseVerify}
            severity="success"
            sx={{ width: '100%' }}
          >
            Verification email resent!
          </Alert>
        </Snackbar>

        <Grid align="center" marginBottom={4}>
          <img src={logo} alt="TETHYS AI logo" style={{ height: 70 }} />
          <Typography variant="h4" paddingTop={0} gutterBottom>
            Sign in
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            to continue to the application
          </Typography>
        </Grid>
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, actions) => {
            setLoading(true)
            setEmail(values.email)

            login(values.email, values.password)
              .then((data) => {
                setLoading(false)
                if (data?.newPasswordRequired) {
                  navigate('/new-password-required', {
                    state: { ...values },
                  })
                } else {
                  const groups = data.idToken.payload['cognito:groups']
                  let redirectPath = location.state?.path
                  const basename = getBaseName(groups)
                  if (!redirectPath) {
                    redirectPath = `${basename}`
                  }
                  navigate(redirectPath, { replace: true })
                }
              })
              .catch((err) => {
                setLoading(false)
                setError(err)
              })
          }}
        >
          <Form>
            <Stack direction="column" spacing={2}>
              {errorMessage && (
                <FormHelperText error={true} component="span">
                  {errorMessage}
                </FormHelperText>
              )}
              <TextField
                disabled={loading}
                name="email"
                label="Email"
                autoFocus={true}
              />
              <TextField
                disabled={loading}
                name="password"
                label="Password"
                type="password"
              />
            </Stack>
            <Button
              loading={loading}
              type="submit"
              style={btnStyle}
              disableElevation
            >
              Sign in
            </Button>
            <Typography>
              <Link
                component="button"
                type="button"
                onClick={() => navigate('/forgetpassword')}
                variant="body2"
              >
                Forgot password?
              </Link>
            </Typography>
            <Typography variant="body2">
              {'New to Tethys AI? '}
              <Link
                component="button"
                type="button"
                variant="body2"
                onClick={() => {
                  // handleTab('event', '2')
                  window.location.href = 'https://tethys.ai/contact/'
                }}
              >
                Join our waiting list
              </Link>
              .
            </Typography>
          </Form>
        </Formik>
      </Paper>
    </Grid>
  )
}

export default Login
