import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { useFormikContext } from 'formik'

const ButtonWrapper = ({ children, ...otherProps }) => {
  const { submitForm } = useFormikContext()

  const handleSubmit = (event) => {
    event.preventDefault()
    submitForm()
  }

  const configButton = {
    variant: 'contained',
    color: 'primary',
    fullWidth: true,
    type: 'submit',
    ...otherProps,
    onClick: handleSubmit,
  }
  return <LoadingButton {...configButton}>{children}</LoadingButton>
}

export default ButtonWrapper
