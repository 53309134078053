import { useState } from 'react'
import {
  Paper,
  Grid,
  Typography,
  Link,
  FormHelperText,
  Snackbar,
  Alert,
} from '@mui/material'
import logo from '../../assets/images/tethys_logo.png'

const VerifyEmail = ({ user }) => {
  const [errorMessage, setErrorMessage] = useState(null)
  const [open, setOpen] = useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const paperStyle = {
    padding: 20,
    width: 300,
    margin: '20px auto',
  }
  return (
    <Grid>
      <Paper elevation={0} style={paperStyle}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            Verification email resent!
          </Alert>
        </Snackbar>
        <Grid align="left" marginBottom={4}>
          <img src={logo} alt="TETHYS AI logo" style={{ height: 30 }} />
          <Typography variant="h6" paddingTop={2} gutterBottom>
            Welcome to Tethys AI
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            verify email to proceed
          </Typography>
        </Grid>
        <Typography variant="body1">
          {
            'An email has been sent to you for user verification. Before proceeding, please check your email for a verification link. If you did not receive the email, '
          }
          <Link
            component="button"
            type="button"
            variant="body1"
            onClick={() => {
              if (user) {
                user.resendConfirmationCode((err, data) => {
                  if (err) {
                    console.error(err)
                    setErrorMessage(err.message)
                  } else {
                    setOpen(true)
                    console.log(data)
                  }
                })
              } else {
                console.error('No user information available')
              }
            }}
          >
            click here to request another
          </Link>
          .
        </Typography>
        <FormHelperText error={true} component="span">
          {errorMessage}
        </FormHelperText>
      </Paper>
    </Grid>
  )
}

export default VerifyEmail
