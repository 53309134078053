import useAuth from './useAuth'
import { getBaseName } from '../utils/frontend'

const useBaseName = () => {
  const { session } = useAuth()
  const groups = session?.idToken?.payload?.['cognito:groups']
  const basename = getBaseName(groups)
  return basename
}

export default useBaseName
