import { CognitoUserPool } from 'amazon-cognito-identity-js'

const getUserPoolData = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'staging':
      return {
        UserPoolId: 'us-east-1_rQbct8Yq2',
        ClientId: '3t43umpi1j581tshghd71ap1h7',
      }
    case 'production':
      return {
        UserPoolId: 'us-east-1_71ZrXAOcF',
        ClientId: '556lill2354imq037cuvs42ufb',
      }
    case 'test':
      return {
        UserPoolId: 'us-east-1_2upR1r8NR',
        ClientId: '5guoemg3c5r1jd1jev88pg803o',
      }
    default:
      return {
        UserPoolId: 'us-east-1_w33VBBMux',
        ClientId: '6nf1iabkhjum0gdod1jb6an9d6',
      }
  }
}

const UserPool = new CognitoUserPool(getUserPoolData())

export { UserPool }
