import React from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'

const TextfieldWrapper = ({ name, disableErrorMesssage, ...otherProps }) => {
  const [field, meta] = useField(name)

  const configTextfield = {
    ...field,
    fullWidth: true,
    variant: 'outlined',
    ...otherProps,
  }

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true
    if (!disableErrorMesssage) {
      configTextfield.helperText = meta.error
    }
  }

  return <TextField {...configTextfield} />
}

export default TextfieldWrapper
