import React, { useState } from 'react'
import { Grid, Paper, Typography, FormHelperText } from '@mui/material'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextField from '../../components/Form/TextField'
import Button from '../../components/Form/Button'
import { UserPool } from '../../utils/cognito'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import logo from '../../assets/images/tethys_logo.png'

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
}

const FORM_VALIDATION = Yup.object().shape({
  firstName: Yup.string().required('Enter first name'),
  lastName: Yup.string().required('Enter last name'),
  email: Yup.string().email('Enter a valid email').required('Enter email'),
  password: Yup.string()
    .min(8, 'min') // Use 8 characters or more for your password
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,}$/,
      'strength'
    ) // Please choose a stronger password. Try a mix of letters, numbers, and symbols.
    .required('required'), // Enter a password
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'match') // Those passwords didn't match. Try again.
    .required('required'), // Confirm your password
})

const passwordErrorMessage = (errors) => {
  const { password, confirmPassword } = errors
  switch (true) {
    case password === 'min':
      return 'Use 8 characters or more for your password'
    case password === 'strength':
      return 'Please choose a stronger password. Try a mix of lowercase letters, uppercase letters, numbers, and symbols.'
    case password === 'required':
      return 'Enter a password'
    case !password && confirmPassword === 'match':
      return "Those passwords didn't match. Try again."
    case !password && confirmPassword === 'required':
      return 'Confirm your password'
    default:
      return null
  }
}

const SignUp = ({ setVerify, setUser }) => {
  const [loading, setLoading] = useState(false)
  const [signUpErrorMessage, setSignUpErrorMessage] = useState(null)
  const paperStyle = {
    padding: 20,
    width: 300,
    margin: '20px auto',
  }
  const helperStyle = {
    marginLeft: '22px',
    marginRight: '22px',
    marginTop: '0px',
    padding: 0,
  }
  const inputStyle = {
    fontSize: 14,
  }
  const btnStyle = {
    marginTop: '16px',
  }
  const columnSpacing = 1
  const rowSpacing = 2
  return (
    <Grid>
      <Paper elevation={0} style={paperStyle}>
        <Grid align="left" marginBottom={4}>
          <img src={logo} alt="TETHYS AI logo" style={{ height: 30 }} />
          <Typography variant="h6" paddingTop={2} gutterBottom>
            Create your Tethys AI Account
          </Typography>
          <Typography variant="body" marginBottom={2}>
            to continue to the application
          </Typography>
        </Grid>
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values) => {
            setLoading(true)
            const attributeGiveName = new CognitoUserAttribute({
              Name: 'given_name',
              Value: values.firstName,
            })
            const attributeFamilyName = new CognitoUserAttribute({
              Name: 'family_name',
              Value: values.lastName,
            })
            UserPool.signUp(
              values.email,
              values.password,
              [attributeGiveName, attributeFamilyName],
              null,
              (err, data) => {
                setLoading(false)
                if (err) {
                  setSignUpErrorMessage(err.message)
                } else {
                  setUser(data.user)
                  setSignUpErrorMessage(false)
                  setVerify(true)
                }
              }
            )
          }}
        >
          {({ errors }) => {
            const errorMessage = passwordErrorMessage(errors)
            return (
              <Form>
                <Grid
                  container
                  columnSpacing={columnSpacing}
                  rowSpacing={rowSpacing}
                >
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading}
                      name="firstName"
                      variant="outlined"
                      label="First Name"
                      size="small"
                      fullWidth={true}
                      autoFocus={true}
                      inputProps={{ style: inputStyle }} // font size of input text
                      InputLabelProps={{ style: inputStyle }} // font size of input label
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={loading}
                      name="lastName"
                      variant="outlined"
                      label="Last Name"
                      size="small"
                      fullWidth={true}
                      inputProps={{ style: inputStyle }} // font size of input text
                      InputLabelProps={{ style: inputStyle }} // font size of input label
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={loading}
                      name="email"
                      variant="outlined"
                      label="Email"
                      size="small"
                      fullWidth={true}
                      inputProps={{ style: inputStyle }} // font size of input text
                      InputLabelProps={{ style: inputStyle }} // font size of input label
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      columnSpacing={columnSpacing}
                      rowSpacing={rowSpacing}
                    >
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={loading}
                          name="password"
                          variant="outlined"
                          label="Password"
                          type="password"
                          size="small"
                          fullWidth={true}
                          inputProps={{ style: inputStyle }} // font size of input text
                          InputLabelProps={{ style: inputStyle }} // font size of input label
                          disableErrorMesssage={true}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={loading}
                          name="confirmPassword"
                          variant="outlined"
                          label="Confirm"
                          type="password"
                          size="small"
                          fullWidth={true}
                          inputProps={{ style: inputStyle }} // font size of input text
                          InputLabelProps={{ style: inputStyle }} // font size of input label
                          disableErrorMesssage={true}
                        />
                      </Grid>
                      <Grid item xs={12} style={helperStyle}>
                        {signUpErrorMessage ? (
                          <FormHelperText error={true} component="span">
                            {signUpErrorMessage}
                          </FormHelperText>
                        ) : (
                          <FormHelperText
                            error={errorMessage !== null}
                            component="span"
                          >
                            {errorMessage
                              ? errorMessage
                              : 'Use 8 or more characters with a mix of letters, numbers & symbols'}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button
                    loading={loading}
                    type="submit"
                    color="primary"
                    variant="contained"
                    style={btnStyle}
                    fullWidth={true}
                    disableElevation
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Paper>
    </Grid>
  )
}

export default SignUp
