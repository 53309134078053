import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Grid, List, ListItem, Stack } from '@mui/material'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import logo from '../../assets/images/tethys-ai1_1__1_-removebg-preview.png'
import { useTheme } from '@mui/material/styles'

function Copyright() {
  return (
    <Typography variant="body4" color="#f6f6f6">
      {'© '}
      {new Date().getFullYear()}
      {' All Rights Reserved'}
    </Typography>
  )
}

function ListItemLink({ text, href }) {
  const theme = useTheme()
  return (
    <ListItem
      sx={{
        px: 0,
        py: 0.5,
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
        },
      }}
    >
      <Link variant="body3" href={href} underline="none" color="#f6f6f6">
        {text}
      </Link>
    </ListItem>
  )
}

ListItemLink.defaultProps = {
  href: '#',
}

export default function StickyFooter() {
  const theme = useTheme()

  return (
    <Box
      component="footer"
      sx={{
        pt: 6.25,
        pb: 2.5,
        mt: 'auto',
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
      <Box
        px={2}
        paddingBottom={6.25}
        sx={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1200px' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                alignItems: 'left',
                [theme.breakpoints.down('md')]: {
                  alignItems: 'center',
                },
              }}
            >
              <img src={logo} alt="Tethys AI logo" width={200} />
              <Typography variant="h7" my={2} color="#f6f6f6">
                More About Us
              </Typography>
              <Typography color="#f6f6f6">
                Tethys AI: Adaptable, AI-Driven Medical
              </Typography>
              <Typography color="#f6f6f6">Image Education</Typography>
              <Box my={2}>
                <Link
                  variant="body1"
                  href="https://tethys.ai/"
                  underline="none"
                  color="#f6f6f6"
                >
                  www.Tethys.AI
                </Link>
              </Box>
              <Stack direction="row" spacing={2}>
                <Link href="https://twitter.com/TethysAI" color="#f6f6f6">
                  <TwitterIcon fontSize="medium" />
                </Link>
                <Link
                  href="https://www.instagram.com/tethysai/"
                  color="#f6f6f6"
                >
                  <InstagramIcon fontSize="medium" />
                </Link>
                <Link
                  href="https://www.linkedin.com/company/tethys-ai/"
                  color="#f6f6f6"
                >
                  <LinkedInIcon fontSize="medium" />
                </Link>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                alignItems: 'left',
                [theme.breakpoints.down('md')]: {
                  alignItems: 'center',
                },
              }}
            >
              <Typography variant="h7" color="#f6f6f6" mb={1}>
                Quick Links
              </Typography>
              <List sx={{ alignItems: 'center' }}>
                <ListItemLink text="Home" href="https://tethys.ai/" />
                <ListItemLink text="About" href="https://tethys.ai/about/" />
                <ListItemLink
                  text="Pricing"
                  href="https://tethys.ai/pricing/"
                />
                <ListItemLink text="Blog" href="https://tethys.ai/blog/" />
                <ListItemLink
                  text="Contact"
                  href="https://tethys.ai/contact/"
                />
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        paddingTop={2.5}
        borderTop={1}
        borderColor="#FFFFFF24"
        sx={{
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Copyright />
      </Box>
    </Box>
  )
}
