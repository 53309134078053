import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import logo from '../assets/images/tethys_logo.png'

export default function NoMatchPage() {
  const location = useLocation()
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Box>
        <img
          src={logo}
          alt="TETHYS AI logo"
          style={{ height: 50, marginBottom: 10 }}
        />
        <Typography component="div" mb={2}>
          <Box fontWeight="fontWeightMedium" display="inline">
            404.{' '}
          </Box>
          That's an error.
        </Typography>
        <Typography>
          The requested URL {location.pathname} was not found on this server.
        </Typography>
        <Typography>That's all we know.</Typography>
      </Box>
    </Grid>
  )
}
